var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorization},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":!_vm.hasAuthorization,"color":"primary","data-test":"createKey-btn"},domProps:{"textContent":_vm._s('Add Public Key')},on:{"click":function($event){_vm.dialog = !_vm.dialog}}})],1)]}}])},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-dialog',{attrs:{"max-width":"520"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"data-test":"publicKeyFormDialog-card"}},[_c('v-card-title',{staticClass:"headline primary",attrs:{"data-test":"text-title"},domProps:{"textContent":_vm._s('New Public Key')}}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',[_c('ValidationProvider',{ref:"providerName",attrs:{"vid":"name","name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","placeholder":"Name used to identify the public key","error-messages":errors,"required":"","data-test":"name-field"},model:{value:(_vm.keyLocal.name),callback:function ($$v) {_vm.$set(_vm.keyLocal, "name", $$v)},expression:"keyLocal.name"}})]}}],null,true)}),_c('v-row',{staticClass:"mt-2 mb-1 px-3"},[_c('v-select',{attrs:{"label":"Device username access restriction","items":_vm.usernameList,"item-text":"filterText","item-value":"filterName","data-test":"access-restriction-field"},model:{value:(_vm.choiceUsername),callback:function ($$v) {_vm.choiceUsername=$$v},expression:"choiceUsername"}})],1),(_vm.choiceUsername==='username')?_c('ValidationProvider',{attrs:{"name":"Username","data-test":"username-validationProvider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username","placeholder":"Username used during the connection","error-messages":errors,"data-test":"username-field"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Hostname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"mt-1 px-3"},[_c('v-select',{attrs:{"label":"Device access restriction","items":_vm.filterList,"item-text":"filterText","item-value":"filterName","data-test":"access-restriction-field"},model:{value:(_vm.choiceFilter),callback:function ($$v) {_vm.choiceFilter=$$v},expression:"choiceFilter"}})],1),_c('v-row',{staticClass:"px-3"},[(_vm.choiceFilter === 'tags')?_c('v-select',{attrs:{"items":_vm.tagNames,"data-test":"tags-field","attach":"","chips":"","label":"Tags","rules":[_vm.validateLength],"error-messages":_vm.errMsg,"menu-props":{ top: true, maxHeight: 150, offsetY: true },"multiple":""},model:{value:(_vm.tagChoices),callback:function ($$v) {_vm.tagChoices=$$v},expression:"tagChoices"}}):_vm._e(),(_vm.choiceFilter === 'hostname')?_c('v-text-field',{attrs:{"label":"Hostname","error-messages":errors,"data-test":"hostname-field"},model:{value:(_vm.hostname),callback:function ($$v) {_vm.hostname=$$v},expression:"hostname"}}):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{ref:"providerData",attrs:{"vid":"key","name":"Data","rules":'required|parseKey:public'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mt-5",attrs:{"label":"Public key data","error-messages":errors,"required":"","messages":_vm.supportedKeys,"data-test":"data-field","rows":"2"},model:{value:(_vm.keyLocal.data),callback:function ($$v) {_vm.$set(_vm.keyLocal, "data", $$v)},expression:"keyLocal.data"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","data-test":"cancel-btn"},domProps:{"textContent":_vm._s('Cancel')},on:{"click":_vm.close}}),_c('v-btn',{attrs:{"text":"","data-test":"create-btn"},domProps:{"textContent":_vm._s('Create')},on:{"click":function($event){return passes(_vm.create)}}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }